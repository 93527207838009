define('Cubix_Newsletter/js/newsletter',[
    "jquery",
    "Magento_Ui/js/modal/modal",
    "mage/cookies",
    "domReady!"

], function($,modal) {
    "use strict";
    $.widget('cubix.newsletterpopup', {
        _create: function () {
            $(document).on("click", ".newsletter-modal .action-close,.close-newsletter", function () {
                $('.newsletter-modal').removeClass('_show');
                $('.modals-overlay').css("display", "none");
                $('body').removeClass('_has-modal');
            });
        },
        _init: function() {
            var $widget = this,
                delay   = this.options.delay,
                time    = this._getDelay(delay),
                cookie = 'newsletter',
                options = {
                  type: 'popup',
                  clickableOverlay: false,
                  innerScroll: true,
                  title: $.mage.__(this.options.title),
                  modalClass: "newsletter-modal",
                  buttons: ''
                };
            if (this._isCookieSet (cookie) != true )  {

                this._logTime(time, function(){
                    $widget._openModal(options, cookie);
                });
            }

            $('#newsletter-validate-detail-popup').on('submit', function (e) {
                e.preventDefault();
                if ($(this).validation('isValid')) {
                    $widget.submitForm($(this));
                }
            });
        },
        /**
         * Handler for the form 'submit' event
         *
         * @param {Object} form
         */
        submitForm: function (form) {
            $('#dh_loading').show();
            var self = this;
            $.ajax({
                url: form.attr('action'),
                data: form.serialize(),
                type: 'post',
                dataType: 'json',
                showLoader: false,
                beforeSend: function () {
                    self.element.parent().find('.messages').remove();
                },
                success: function (response) {
                    if (response.error) {
                        $('.block.newsletter').html('<div class="messages"><div class="message message-error error"><div>'+response.message+'</div></div></div>');
                    } else {
                        self.element.find('input').val('');
                        $('.block.newsletter').html('<div class="messages"><div class="message message-success success"><div>'+response.message+'</div></div></div>');
                        
                        setTimeout(function() {
                            $('.newsletter-modal').removeClass('_show');
                            $('.modals-overlay').css("display", "none");
                        }, 2000);
                        $('#dh_loading').hide();
                    }
                    
                },
                error: function() {
                    self.element.after('<div class="messages"><div class="message message-error error"><div>'+$t('An error occurred, please try again later.')+'</div></div></div>');
                }
            });
            $('#dh_loading').hide();
        },
        _openModal: function (options, cookie) {

            var html   = this.element,
                popup  = modal(options, html);

               html.modal('openModal');
               this._setCookie(cookie);
        },

        _getDelay: function (delay) {

            var cookie = $.mage.cookies.get('popup-timing');
            if (cookie > 0 ) {
                return delay - cookie
            } else {
                return delay
            }
        },

        _logTime: function (i, callback) {
            callback = callback || function(){};
            var int = setInterval(function() {
                $.mage.cookies.set('popup-timing', i);
                i-- || (clearInterval(int), callback());
            }, 1000);
        },

        _setCookie: function (cookie) {
            $.mage.cookies.set(cookie, 'yes',
                {lifetime: 2592000});
        },

        _isCookieSet: function (cookie) {
            if ($.mage.cookies.get(cookie) == 'yes') {
                return true;
            }
        }
    });
    return $.cubix.popupnewsletter;
});

